import {
  FirstbookAuthCredentials,
  OpenEBooksAuthCredentials,
} from "../auth/types";

export const text = async (response: Response) => await response.text();
// we should make this return unknown and then handle downstream type errors from it
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const json = async (response: Response) => await response.json();

/**
 * Used to log at any stage in the promise chain:
 *
 *  fetch(url)
 *    .catch(handleFetchError(url))
 *    .then(text)
 *    .then(log)      // logs the text
 *    .then(parseEntry)
 *    ...
 */
export const log = async <T>(val: T) => {
  console.log(val);
  return val;
};

export const isFirstBookAuthCredentials = (
  credentials: OpenEBooksAuthCredentials,
): credentials is FirstbookAuthCredentials => {
  return "basicToken" in credentials && "expiresAt" in credentials;
};
