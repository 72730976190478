import React from "react";
import ErrorComponent from "components/chakra-ui/Error";
import { OEBFailureOrDefect, isOEBFailure } from "oe-shared/errors";
import { isDefect } from "@ereading/shared/errors";

type AnyError = OEBFailureOrDefect | Error;

export type FallbackProps = {
  error: AnyError;
  info?: React.ErrorInfo;
  clearError?: () => void;
};

export const DefaultFallback: React.FC<
  React.PropsWithChildren<FallbackProps>
> = ({ error }) => {
  let title = "Something went wrong";
  let detail = "An unexpected error occurred";
  let status = undefined;

  if (isOEBFailure(error)) {
    if (error._tag === "FetchError") {
      title = "We're sorry...";
      detail = `There was an unexpected error fetching ${error.url}`;
    } else if (error._tag === "PageNotFoundError") {
      title = "We're sorry...";
      detail = "Page not found";
      status = 404;
    }
  } else if (error instanceof Error && !isDefect(error)) {
    title = error.name;
    detail = error.message;
  }

  return <ErrorComponent title={title} detail={detail} status={status} />;
};

type ErrorState = { error?: AnyError; info?: React.ErrorInfo };
const initialState: ErrorState = { error: undefined, info: undefined };
class DefaultErrorBoundary extends React.Component<
  {
    FallbackComponent: React.ComponentType<
      React.PropsWithChildren<FallbackProps>
    >;
    children?: React.ReactNode;
  },
  ErrorState
> {
  static getDerivedStateFromError(error: AnyError) {
    return { error };
  }

  state = initialState;

  handleClearError() {
    this.setState(initialState);
  }

  componentDidCatch(error: AnyError, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      info: errorInfo,
    });
  }

  render() {
    const { error } = this.state;
    const { FallbackComponent } = this.props;

    if (error) {
      return <FallbackComponent error={error} />;
    }

    return this.props.children;
  }
}

export const ErrorBoundary: React.FC<
  React.PropsWithChildren<{
    fallback?: React.ComponentType<React.PropsWithChildren<FallbackProps>>;
  }>
> = ({ children, fallback: Fallback = DefaultFallback }) => {
  return (
    <DefaultErrorBoundary FallbackComponent={Fallback}>
      {children}
    </DefaultErrorBoundary>
  );
};

export default function withErrorBoundary<T>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  Fallback: React.ComponentType<
    React.PropsWithChildren<FallbackProps>
  > = DefaultFallback,
) {
  const Wrapped = (props: T) => {
    return (
      <DefaultErrorBoundary FallbackComponent={Fallback}>
        {/* @ts-ignore */}
        <Component {...props} />
      </DefaultErrorBoundary>
    );
  };

  // Format for display in DevTools
  const name = Component.displayName || Component.name;
  Wrapped.displayName = name
    ? `WithErrorBoundary(${name})`
    : "WithErrorBoundary";

  return Wrapped;
}
