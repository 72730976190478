export type NRConfig = {
  agentID: string;
  applicationID: string;
  accountID?: string;
  trustKey?: string;
  licenseKey?: string;
};

export type NRInfo = {
  applicationID: string;
  beacon?: string;
  errorBeacon?: string;
  licenseKey?: string;
  sa?: number;
};

// Default QA configs
const defaultConfig: NRConfig = {
  accountID: "121334",
  trustKey: "121334",
  agentID: "1588938906",
  licenseKey: "NRBR-45e7d2f4db9f64a726b",
  applicationID: "1446239842",
};
const defaultInfo: NRInfo = {
  // FedRAMP-compliant account
  beacon: "gov-bam.nr-data.net",
  errorBeacon: "gov-bam.nr-data.net",
  licenseKey: "NRBR-45e7d2f4db9f64a726b",
  applicationID: "1446239842",
  sa: 1,
};
export const qaConfig: NRConfig = {
  agentID: "1588933749",
  applicationID: "1446239842",
};
export const qaInfo: NRInfo = {
  applicationID: "1446239842",
};
export const prodConfig: NRConfig = {
  agentID: "1588692255",
  applicationID: "1588692255",
};
export const prodInfo: NRInfo = {
  applicationID: "1588692255",
};

export function setup(config: NRConfig, info: NRInfo) {
  if (typeof window?.NREUM === "undefined") return;
  window.NREUM.loader_config = { ...defaultConfig, ...config };
  window.NREUM.info = { ...defaultInfo, ...info };
}

// Setup the Newrelic browser agent config for different deploy environment,
export function NewRelicBrowserSetup(environment: string | undefined) {
  if (!environment) {
    console.warn("Unknown envrionment %o", environment);
    return;
  }

  if (environment === "staging") {
    console.info("NR: Setup staging config.");
    setup(qaConfig, qaInfo);
  } else if (environment === "production") {
    console.info("NR: Setup production config.");
    setup(prodConfig, prodInfo);
  }
}
