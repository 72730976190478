import { OPDS1 } from "@nypl/opds";
import { formatErrors } from "@effect/schema/TreeFormatter";
import { ParseError as SchemaParseError } from "@effect/schema/ParseResult";
import { InvalidRecord } from "../models/annotations";
import {
  Defect,
  Failure,
  PageNotFoundError,
  SharedFailure,
  UnauthorizedError,
  isDefect,
} from "../../shared/errors";

export type AnyOEBFailure =
  | SharedFailure
  | PatronMustOptInToSyncError
  | ParseError
  | InvalidAnnotationsError;

export type AnyOEBDefect = CMServerError | Defect;

export type OEBFailureOrDefect = AnyOEBFailure | AnyOEBDefect;

/**
 * Wraps a schema parse error from @effect/schema.
 */
export class ParseError extends Failure {
  readonly _tag = "ParseError";
  readonly base: SchemaParseError;
  readonly errors: SchemaParseError["errors"];
  constructor(base: SchemaParseError) {
    super(base);
    this.base = base;
    this.errors = base.errors;
  }

  override get message(): string {
    return `ParseError: ${formatErrors(this.errors)}`;
  }
}

/**
 * Wraps a set of annotation parsing errors before logging them to NR.
 */
export class InvalidAnnotationsError extends Failure {
  readonly _tag = "InvalidAnnotationsError";
  readonly invalidAnnotations: InvalidRecord[];
  constructor(invalidAnnotations: InvalidRecord[]) {
    super();
    this.invalidAnnotations = invalidAnnotations;
  }
  get message(): string {
    return `InvalidAnnotationsError: ${JSON.stringify(
      this.invalidAnnotations,
    )}`;
  }
}

// returned by the annotations endpoint if the patron is not opted-in to syncing
export class PatronMustOptInToSyncError extends Failure {
  readonly _tag = "PatronMustOptInToSyncError";
  constructor() {
    super();
  }
  message =
    "PatronMustOptInToSyncError: Patron attempted to sync without being opted-in to syncing.";
}

/**
 * The CMServerError extends Defect because most of the time it is an
 * unexpected error. The expected failures we should make specific
 * error types for, such as the UnauthorizedError and PageNotFoundError,
 * which `fromResponsePromise` can detect and return.
 *
 * This means that we _don't_ check error insanceof CMServerError to display
 * a specific error from the server. Instead, we should just display a generic
 * error message to users if a Defect is thrown. The devMessage & stack
 * trace should be sent to New Relic for further debugging.
 */
export class CMServerError extends Defect {
  constructor(
    readonly status: number,
    readonly statusText: string,
    readonly title: string,
    readonly detail: string,
    readonly url: string,
    readonly devMessage: string = `${status} ServerError - ${statusText}`,
  ) {
    super(devMessage);
  }

  static async fromResponsePromise(
    response: Response,
    devMessage?: string,
  ): Promise<CMServerError | UnauthorizedError | PageNotFoundError> {
    const { status, statusText } = response;
    if (status === 401) {
      // it would be good in the future to allow specifying what the server
      // can respond with for each status code.
      const problemDoc = (await response.json()) as OPDS1.ProblemDocument;
      return new UnauthorizedError(
        problemDoc.detail ?? "Please sign in.",
        problemDoc.title,
      );
    }
    if (status === 404) return new PageNotFoundError(response.url);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const body = await response.json();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { title, detail } = body;
    return new CMServerError(
      status,
      statusText,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      detail,
      response.url,
      devMessage,
    );
  }

  override get message(): string {
    return `ServerError: ${this.title} - ${this.detail}`;
  }
}

/**
 * This function tells us the error is a known OEB failure. We can then use a
 * switch statement to handle the different types.
 */
export function isOEBFailure(e: unknown): e is AnyOEBFailure {
  if (
    typeof e === "object" &&
    e &&
    "_isFailure" in e &&
    e._isFailure === true
  ) {
    return true;
  }
  return false;
}
// check if an unknown is a specific type of failure
export function isOEBFailureTag<T extends AnyOEBFailure>(
  e: unknown,
  tag: T["_tag"],
): e is T {
  return isOEBFailure(e) && e._tag === tag;
}

/**
 * Useful for in a catch block:
 * catch(e) {
 *   assertIsOEBFailure(e);
 *   // e is now AnyOEBFailure
 * }
 */
export function assertIsOEBFailure(e: unknown): asserts e is AnyOEBFailure {
  if (!isOEBFailure(e)) {
    throw e;
  }
}

/**
 * If the unknown is a failure, returns the failure.
 * Otherwise returns a new defect with the arguments supplied.
 */
export function normalizeOEBError(
  e: unknown,
  ...args: ConstructorParameters<typeof Defect>
) {
  if (isOEBFailure(e) || isDefect(e)) {
    // add any extra info to the existing error object
    const info = args?.[1] ?? {};
    return Object.assign(e, info);
  }
  return new Defect(args[0], {
    ...args[1],
    cause: e,
  });
}
