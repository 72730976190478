import Link, { LinkProps } from "next/link";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { useRouter } from "next/router";
import { Link as ChakraLink, ListItem } from "@chakra-ui/react";
import { UrlObject } from "url";
import { CATALOG_ROOT_PATH, LOANS_PATH } from "utils/constants";
import { showOnDesktop } from "./Header";

type HeaderLinkWithIconProps = {
  href: string | (string & UrlObject);
};

const HeaderLinkWithIcon = ({
  href,
  children,
}: React.PropsWithChildren<HeaderLinkWithIconProps>) => {
  return (
    // setting minW for Safari bug
    <DesktopHeaderLink href={href} px={3} minW="120px">
      {children}
    </DesktopHeaderLink>
  );
};

export const MyBooksHeaderLink: React.FC = () => {
  return (
    <HeaderLinkWithIcon href={LOANS_PATH}>
      <LocalLibraryOutlinedIcon sx={{ marginRight: 1 }} />
      My books
    </HeaderLinkWithIcon>
  );
};

export const AllBooksHeaderLink: React.FC = () => {
  return (
    <HeaderLinkWithIcon href={CATALOG_ROOT_PATH}>
      <ExploreOutlinedIcon sx={{ marginRight: 1 }} />
      Discover
    </HeaderLinkWithIcon>
  );
};

type HeaderLinkProps = React.ComponentProps<typeof ChakraLink> & LinkProps;

export const DesktopHeaderLink = ({
  children,
  href,
  ...props
}: React.PropsWithChildren<HeaderLinkProps>) => {
  const router = useRouter();
  const selected = isSelected(router.pathname, href);

  return (
    <ListItem flexShrink={0}>
      <Link href={href} passHref legacyBehavior>
        <ChakraLink
          variant="header"
          px={{ base: 3, sm: 2, lg: 3 }}
          py="5"
          sx={showOnDesktop}
          {...props}
          {...(selected && {
            fontWeight: "bold",
            bgColor: "ui.white",
            textDecoration: "none",
          })}
        >
          {children}
        </ChakraLink>
      </Link>
    </ListItem>
  );
};

export const isSelected = (pathname: string, href: string) => {
  return (
    pathname === href ||
    // collection page is the main catalog page
    (pathname.includes("collectionUrl") && href === CATALOG_ROOT_PATH)
  );
};
