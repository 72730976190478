import track from "analytics/track";
import { PageNotFoundError, UnauthorizedError } from "@ereading/shared/errors";
import { SWRConfiguration } from "swr";

const swrConfig: SWRConfiguration = {
  // we don't generally need to revalidate our data very often
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 2000,
  onError: (err, key, config) => {
    // we simply will track the error here.
    track.error(err, {
      customAttributes: {
        swrConfig: JSON.stringify({
          key,
          config,
        }),
      },
    });
  },
  onErrorRetry: (error, _key, _config, revalidate, { retryCount = 0 }) => {
    if (error instanceof UnauthorizedError) return;
    if (error instanceof PageNotFoundError) return;
    // Only retry up to 10 times.
    if (retryCount >= 10) return;

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
};

export default swrConfig;
