import { Defect, FetchError } from "@ereading/shared/errors";
import { CMServerError, isOEBFailure } from "../errors";

/**
 * Fetch doesn't reject if it receives a response from the server,
 * only if the actual fetch fails due to network failure or permission failure like
 * CORS issues. We catch and rethrow a wrapped error in those cases to give more info.
 */
export const handleFetchError =
  (input: RequestInfo) => async (reason: unknown) => {
    const url = typeof input === "string" ? input : input.url;
    // allow existing failure to pass through.
    if (isOEBFailure(reason)) throw reason;
    throw new FetchError(url, reason);
  };

export const handleCMError = async (response: Response) => {
  if (!response.ok) {
    throw await CMServerError.fromResponsePromise(response);
  }
  return response;
};

export const handleParsingError =
  (input: RequestInfo) => async (reason: unknown) => {
    const url = typeof input === "string" ? input : input.url;
    // allow existing failures to pass through.
    if (isOEBFailure(reason)) {
      throw reason;
    }

    throw new Defect("Failed to parse fetch response into an OPDS Feed", {
      cause: reason,
      url,
    });
  };
