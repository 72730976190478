import { AppProps, NextWebVitalsMetric } from "next/app";
import { ErrorBoundary } from "components/chakra-ui/ErrorBoundary";
import "system-font-css";
import "css-overrides.css";
import track from "analytics/track";
import ProgressBar from "@badrap/bar-of-progress";
import Router from "next/router";
import { mdxComponents } from "components/chakra-ui/mdx/MDXComponents";
import { MDXProvider } from "@mdx-js/react";
import { ChakraProvider } from "@chakra-ui/react";
import webTheme from "components/chakra-ui/theme";
import NewRelicSnippet from "analytics/newRelicSnippet";
import { GTMScript } from "components/GTMScript";

if (process.env.NEXT_PUBLIC_ENABLE_MSW === "true") {
  const { initMocks } = await import("../../msw");
  await initMocks();
}

const progress = new ProgressBar({
  size: 2,
  color: "#0070f3",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const MyApp = (props: AppProps) => {
  const { Component, pageProps } = props;

  return (
    <ErrorBoundary>
      <GTMScript />
      <NewRelicSnippet />
      <MDXProvider components={mdxComponents}>
        <ChakraProvider theme={webTheme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </MDXProvider>
    </ErrorBoundary>
  );
};

/**
 * Note: this if statement MUST use the full process.env.NODE_ENV === "development" check
 * otherwise the code will not be removed at compile time and react-axe will be bundled
 * in production, which increases bundle size by hundreds of kb
 *
 * Uncomment to enable react-axe
 */
// if (process.env.NODE_ENV === "development" && !IS_SERVER && REACT_AXE) {
//   const axe = (await import("@axe-core/react")).default;
//   axe(React, ReactDOM, 1000, {});
// }

export function reportWebVitals(metric: NextWebVitalsMetric) {
  track.webVitals(metric);
}

export default MyApp;
