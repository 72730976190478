import { SystemStyleObject, useMediaQuery } from "@chakra-ui/react";
import { SimpleBook } from "oe-shared/models";
import * as React from "react";

type BookRefs = {
  [id: string]: React.MutableRefObject<HTMLLIElement | null>;
};
type CurrentBook = {
  index: number;
  /**
   * The following dictates whether we snap to a book.
   * "Snapping" to a book means we scroll the currentBook's
   * left edge so that it is up against leftmost edge of the lane
   */
  snap: boolean;
};

export const carouselBreakpoints = [
  "(min-width: 20em)",
  "(min-width: 38em)",
  "(min-width: 60em)",
  "(min-width: 80em)",
  "(min-width: 100em)",
];
const booksToShow = [3, 4, 3, 4, 5, 6];

/**
 * The "sizes" string allows the next/image component to generate a srcSet relevant
 * to the sizes of the image at different breakpoints, which in turn allows the
 * browser to select only the size image it needs at a given breakpoint.
 *  - https://web.dev/learn/design/responsive-images/#sizes
 *  - https://nextjs.org/docs/api-reference/next/image#sizes
 */
export const imageSizes = ["", ...carouselBreakpoints.map((bp) => `${bp} `)]
  .map((bp, i) => `${bp}${100 / booksToShow[i]}vw`)
  .join(", ");

/**
 * This is a style object used on the carousel itself. We
 * define it here to keep everything defining the number of
 * books on display together in one place.
 */
export const maxWidthStyles: SystemStyleObject = {
  flex: `0 0 ${100 / booksToShow[0]}%`,
  [`@media ${carouselBreakpoints[0]}`]: {
    flex: `0 0 ${100 / booksToShow[1]}%`,
  },
  [`@media ${carouselBreakpoints[1]}`]: {
    flex: `0 0 ${100 / booksToShow[2]}%`,
  },
  [`@media ${carouselBreakpoints[2]}`]: {
    flex: `0 0 ${100 / booksToShow[3]}%`,
  },
  [`@media ${carouselBreakpoints[3]}`]: {
    flex: `0 0 ${100 / booksToShow[4]}%`,
  },
  [`@media ${carouselBreakpoints[4]}`]: {
    flex: `0 0 ${100 / booksToShow[5]}%`,
  },
};

const getBookRefs = (books: SimpleBook[]) => {
  /** keep track of a ref for each book */
  const bookRefs: BookRefs = books.reduce<{
    [id: string]: React.MutableRefObject<HTMLLIElement | null>;
  }>((acc, value) => {
    const ref = React.createRef<HTMLLIElement>();
    acc[value.id] = ref;
    return acc;
  }, {});
  return { bookRefs };
};

export function useCarousel(books: SimpleBook[]) {
  /** we will use state to determine which book should be in view */
  const [currentBook, setCurrentBook] = React.useState<CurrentBook>({
    index: 0,
    snap: true,
  });
  const browserIsScrollingRef = React.useRef(false);

  const [sm, md, l, xl, xxl] = useMediaQuery(carouselBreakpoints);
  const numberOfBooks = xxl
    ? booksToShow[5]
    : xl
      ? booksToShow[4]
      : l
        ? booksToShow[3]
        : md
          ? booksToShow[2]
          : sm
            ? booksToShow[1]
            : booksToShow[0];

  // we need a ref to the UL element so we can scroll it
  const scrollContainerRef = React.useRef<HTMLUListElement | null>(null);

  /**
   * We compute these values within a useMemo hook so that they don't change
   * on every render
   */
  const { bookRefs } = React.useMemo(() => getBookRefs(books), [books]);

  // vars for when we are at beginning of a lane

  const isAtStart = currentBook.index === 0;
  const isAtEnd = currentBook.index + numberOfBooks >= books.length;

  /** Handlers for button clicks */
  const handleRightClick = () => {
    if (isAtEnd) return;
    /**
     * The next book is based on the "numberOfBooks" in view.
     * We want to move to the last book that is currently "in view"
     * (it's only partially in view)
     */
    setCurrentBook((book) => {
      return {
        snap: true,
        index: isAtEnd ? book.index : book.index + (numberOfBooks - 1),
      };
    });
  };
  const handleLeftClick = () => {
    if (isAtStart) return;
    setCurrentBook((book) => {
      const next = book.index - (numberOfBooks - 1);
      return {
        snap: true,
        index: next > 0 ? next : 0,
      };
    });
  };

  // will be used to set a timeout when the browser is auto scrolling
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const browserScrollTime = 1000; // guess how long the browser takes to scroll

  /**
   * This effect is used to snap us to a particular book when the
   * currentBook changes. It bails out if snap is false, which is
   * the case when the user is free-scrolling
   */
  React.useEffect(() => {
    if (!currentBook.snap) return;
    const currentIndex = currentBook.index;
    // we explicitly state this can be undefined because the array might be empty
    // and typescript doesn't catch this kind of error
    const nextBook: SimpleBook | undefined = books[currentIndex];
    // if the nextBook is undefined, don't do anything
    if (!nextBook) return;
    const nextBookRef = bookRefs[nextBook.id];
    const bookX = nextBookRef.current?.offsetLeft || 0;

    scrollContainerRef.current?.scrollTo({
      left: bookX,
      behavior: "smooth",
    });
    // allows us to bail out of handleScroll when the browser is autoscrolling
    browserIsScrollingRef.current = true;
    // clear the old timeout and set a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      browserIsScrollingRef.current = false;
    }, browserScrollTime);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [currentBook, books, bookRefs]);

  const getBookWidth = () => {
    const firstBookId = books[0].id;
    const firstBookRef = bookRefs[firstBookId];
    const firstBookWidth = firstBookRef.current?.offsetWidth || 0;
    return firstBookWidth;
  };
  /**
   * Handles the user's free-scrolling interaction. Will bail out if
   * the browser is scrolling via the scrollTo method, which does trigger
   * this event. It will keep the current book up to date as we free
   * scroll so that the next arrow button click will take us to the right
   * book.
   */
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    if (browserIsScrollingRef.current) return;
    const position = e.currentTarget.scrollLeft;
    const bookWidth = getBookWidth();
    const currentIndex = Math.floor(position / bookWidth);
    setCurrentBook({ index: currentIndex, snap: false });
  };

  return {
    handleScroll,
    handleRightClick,
    handleLeftClick,
    bookRefs,
    scrollContainerRef,
    isAtEnd,
    isAtStart,
  };
}
