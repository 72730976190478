import {
  OPDSEntry,
  CompleteEntryLink,
  OPDSAcquisitionLink,
  OPDSArtworkLink,
} from "opds-feed-parser";
import { AxisNowWebpubMediaType, ReadOnlineMediaType } from "@nypl/opds/opds1";
import {
  OpenEBook,
  Book,
  SIMPLIFIED_FICTION_STATUS,
  SIMPLIFIED_GENRE,
} from "./types";
import createDomPurify from "dompurify";
import {
  findRevokeUrl,
  isArtworkLink,
  isBorrowLink,
  isRelatedLink,
  isSupportedOpenEBookLink,
  isTrackOpenBookLink,
  resolve,
} from "../utils/shared";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let DOMPurify: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let htmlDecoder: any;

/**
 * Parse an OPDS 1 entry into a Book model.
 */

if (typeof window === "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-require-imports
  const { JSDOM } = require("jsdom");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const window = new JSDOM().window;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  DOMPurify = createDomPurify(window);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  htmlDecoder = createHtmlDecoder(window.document);
} else {
  DOMPurify = createDomPurify();
  htmlDecoder = createHtmlDecoder(document);
}
/**
 * Decode string which sometimes contain HTML entities, textarea.value retains the HTML tag if's any
 */
export function createHtmlDecoder(document: Document) {
  return (str: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };
}

export default function entryToBook(
  entry: OPDSEntry,
  feedUrl: string,
): OpenEBook {
  const authors = entry.authors
    .map((author) => {
      return author.name;
    })
    .filter((name) => name !== undefined);

  const contributors = entry.contributors.map((contributor) => {
    return contributor.name;
  });

  let imageUrl,
    imageThumbLink,
    fullImageUrl = "";
  const artworkLinks = entry.links.filter(isArtworkLink);

  if (artworkLinks.length > 0) {
    imageThumbLink = artworkLinks.find(
      (link) => link.rel === "http://opds-spec.org/image/thumbnail",
    );
    if (imageThumbLink) {
      imageUrl = resolve(feedUrl, imageThumbLink.href);
    } else {
      console.log(
        `WARNING: missing tumbnail image for ${entry.title}. Defaulting to use full image`,
      );
      imageUrl = resolve(feedUrl, artworkLinks[0].href);
    }

    fullImageUrl = (
      artworkLinks.find(
        (link) => link.rel === "http://opds-spec.org/image",
      ) as OPDSArtworkLink
    ).href;
  }

  const detailLink = entry.links.find(
    (link) => link instanceof CompleteEntryLink,
  ) as CompleteEntryLink;

  const detailUrl = resolve(feedUrl, detailLink.href);

  const { category, genres } = entry.categories
    .filter(
      (category) =>
        category.scheme === SIMPLIFIED_FICTION_STATUS ||
        category.scheme === SIMPLIFIED_GENRE,
    )
    .reduce(
      (acc, category) => {
        if (category.scheme === SIMPLIFIED_FICTION_STATUS) {
          acc.category = category.label;
        } else {
          acc.genres.push(category.label);
        }
        return acc;
      },
      { category: "", genres: [] as string[] },
    );

  const relatedLinks = entry.links.filter(isRelatedLink);
  const relatedLink = relatedLinks.length > 0 ? relatedLinks[0] : null;

  const trackOpenBookLink = entry.links.find(isTrackOpenBookLink);

  const acquisitionLink = entry.links.find(isSupportedOpenEBookLink);

  let availability, holds, copies, borrowLink, fulfillmentLink;
  if (acquisitionLink) {
    ({ availability, holds, copies } = acquisitionLink);
    // if the acquisition link is a borrow link, assign it as such,
    // otherwise, construct a fulfillment link
    if (isBorrowLink(acquisitionLink)) {
      borrowLink = acquisitionLink;
    } else {
      fulfillmentLink = buildFulfillmentLink(acquisitionLink, feedUrl);
    }
  }

  const revokeUrl = findRevokeUrl(entry.links);

  const book: Book = {
    id: entry.id,
    title: entry.title,
    series: Object.assign({}, entry.series),
    authors: authors,
    contributors: contributors,
    subtitle: entry.subtitle,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    summary:
      entry.summary.content &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      htmlDecoder(DOMPurify.sanitize(entry.summary.content)),
    imageUrl: imageUrl as string,
    fullImageUrl,
    availability: {
      ...availability,
      // we type cast status because our internal types
      // are stricter than those in OPDSFeedParser.
      status: availability?.status as "available",
    },
    holds: holds,
    copies: copies,
    publisher: entry.publisher,
    published: entry.issued && formatDate(entry.issued),
    category: category,
    genres: genres,
    language: entry.language,
    url: detailUrl,
    relatedUrl: relatedLink?.href ?? null,
    trackOpenBookUrl: trackOpenBookLink?.href ?? null,
  };

  // it's a borrowable book
  if (borrowLink) {
    return {
      ...book,
      status: "borrowable",
      borrowUrl: borrowLink.href,
    };
  }

  // it's fulfillable
  return {
    ...book,
    status: "fulfillable",
    // FIXME: refactor fulfillmentLinks array into a single object https://jira.nypl.org/browse/OE-479
    fulfillmentLinks: fulfillmentLink ? [fulfillmentLink] : [],
    revokeUrl: revokeUrl,
  };
}

function formatDate(inputDate: string): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(inputDate);
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const month = monthNames[monthIndex];
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
}

function buildFulfillmentLink(link: OPDSAcquisitionLink, feedUrl: string) {
  return {
    url: resolve(feedUrl, link.href),
    contentType: AxisNowWebpubMediaType as ReadOnlineMediaType,
  };
}
