import { isOEBFailure } from "oe-shared/errors";

import { NextWebVitalsMetric } from "next/app";
import { NEW_RELIC_API_KEY } from "utils/env";
import { isDefect } from "@ereading/shared/errors";

type PageData = {
  path: string;
  codePath: string;
  appEnvironment: Record<string, unknown>;
  name?: string;
  collectionUrl?: string;
  bookUrl?: string;
};
// doesn't track an event, just updates the data layer for the page
function pageview(page: PageData) {
  window?.dataLayer?.push({
    event: "pageview",
    page: {
      ...page,
      // clear out the collection/book url and library on each page view if it isn't present
      // in the new page view
      name: page.name ?? undefined,
      collectionUrl: page.collectionUrl ?? undefined,
      bookUrl: page.bookUrl ?? undefined,
    },
  });
}

async function sendServerEvent(url: string | null) {
  if (url) {
    try {
      await fetch(url);
      return true;
    } catch (e) {
      error(e as Error);
      console.error(
        "The previous error occurred while attempting to track a server event to: ",
        url,
      );
    }
  }
  return true;
}

async function openBook(url: string | null) {
  return sendServerEvent(url);
}

// allows us to track performance using web vitals reports
// https://nextjs.org/docs/advanced-features/measuring-performance
function webVitals({ id, name, value, label }: NextWebVitalsMetric) {
  window.dataLayer?.push("performance_metric_recorded", {
    metric_name: name,
    metric_category:
      label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    metric_value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    metric_id: id, // id unique to current page load
  });
}

type CustomAttribute = Record<string, string | number>;

function error(
  e: Error,
  {
    customAttributes,
  }: {
    customAttributes?: CustomAttribute;
  } = {},
) {
  if (!NEW_RELIC_API_KEY) return;

  /**
   * The keys of the customAttributes obj are the filter key in the NR dashboard for the JS Error section
   * e.g.
   *   CustomAttribute: {
   *      "Fetch Info": { ... },
   *      "Error Info": '..'
   *      "Unsupported": '...',
   *   }
   *  turns into filter options "Fetch Info", "Error Info", and "unsupported"
   */
  if (isDefect(e) || isOEBFailure(e)) {
    console.error(`${e._tag}:\n${JSON.stringify(e)}`);
    customAttributes = {
      ...customAttributes,
      [`${e._tag}`]: JSON.stringify({
        ...e,
      }),
    };
  } else {
    console.error(`Unknown error:\n${e}`);
    customAttributes = {
      ...customAttributes,
      "Unknown Error": JSON.stringify({
        ...e,
      }),
    };
  }
  // Send to new relic dashboard
  if (typeof window === "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const newrelic = require("newrelic");
    newrelic.noticeError(e, customAttributes);
  } else {
    window.newrelic.noticeError(e, customAttributes);
  }
}

const track = {
  error,
  webVitals,
  pageview,
  openBook,
};
export default track;
