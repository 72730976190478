import * as S from "@effect/schema/Schema";

/**
 * This is the data model of the bookmarks and reading position used
 * by the native readers.
 */

export const MediaType = S.string.pipe(S.nonEmpty());
export const Href = S.string.pipe(S.nonEmpty());

/**
 * Locator for EPUB media type
 *
 * @property {string} type The RFC 6838 media type.
 * @property {string} locatorType For epubs, this must always be "epub".
 * @property {string} href Location reference in the publication
 * @property {number} chapterProgression Chapter progression as float in range [0, 1]
 * @property {number} totalProgression Contains an overall progression as float in range [0, 1] in the publication based on the reading order. Used for sorting and display and not for navigation purposes.
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 * @property {string} timeCreated The creation time of the reading position with the format based on ISO 8610
 */
export const LocatorEPUB = S.struct({
  type: MediaType,
  locatorType: S.literal("epub"),
  href: Href,
  chapterProgression: S.number,
  totalProgression: S.optional(S.number),
  title: S.optional(S.string),
  timeCreated: S.optional(S.string),
});
export type LocatorEPUB = S.Schema.To<typeof LocatorEPUB>;

/**
 * Locator for PDF publications
 *
 * @property {string} type The RFC 6838 media type.
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 * @property {string} locatorType For pdfs, this must always be "pdf".
 * @property {string} href Location reference in the publication
 * @property {number} page Non-negative integer page index
 * @property {string} timeCreated The creation time of the reading position with the format based on ISO 8610
 */
export const LocatorPDF = S.struct({
  type: MediaType,
  locatorType: S.literal("pdf"),
  href: Href,
  page: S.number,
  title: S.optional(S.string),
  timeCreated: S.optional(S.string),
});
export type LocatorPDF = S.Schema.To<typeof LocatorPDF>;

/**
 * Locator for audiobooks.
 *
 * @property {string} locatorType For audiobooks, this must always be "audio".
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 * @property {number} [part] Non negative part index for Findaway audiobooks, 0 otherwise
 * @property {number} chapter Non negative chapter (or sequence) index
 * @property {number} chapterDuration Total duration of the chapter in milliseconds
 * @property {number} elapsedTime Current elapsed time in the chapter in millisconds
 * @property {string} timeCreated The creation time of the reading position with the format based on ISO 8610
 */
export const LocatorAudiobook = S.struct({
  type: MediaType,
  locatorType: S.literal("audio"),
  part: S.number,
  chapter: S.number,
  chapterDuration: S.number,
  elapsedTime: S.number,
  title: S.optional(S.string),
  timeCreated: S.optional(S.string),
});
export type LocatorAudiobook = S.Schema.To<typeof LocatorAudiobook>;

/**
 * The unique loan id of the publicaton
 */
export const LoanId = S.string.pipe(S.nonEmpty());
export type LoanId = S.Schema.To<typeof LoanId>;

/**
 * Locator union type. This is used inside a bookmark, or to represent
 * a reading position. The `type` property is used to discriminate the union.
 */
export const Locator = S.union(LocatorEPUB, LocatorPDF, LocatorAudiobook);
export type Locator = S.Schema.To<typeof Locator>;

export const Bookmark = S.struct({
  id: S.string.pipe(S.nonEmpty()),
  locator: Locator,
});
export type Bookmark = S.Schema.To<typeof Bookmark>;

export type BookmarkRecord = Record<string, Bookmark[]>;

/**
 * NewPartialBookmark is the type sent by the Reader when creating a bookmark.
 * It doesn't yet have an `id` or `status` since those are set by the server
 * and/or the web.
 */
export const NewBookmark = Bookmark.pipe(S.omit("id"));
export type NewBookmark = S.Schema.To<typeof NewBookmark>;

export type CREATE_BOOKMARK_EVENT = "CREATE_BOOKMARK";
export const CreateBookmarkAction = S.struct({
  bookmark: NewBookmark,
  loanId: LoanId,
});
export type CreateBookmarkAction = S.Schema.To<typeof CreateBookmarkAction>;

export type DELETE_BOOKMARK_EVENT = "DELETE_BOOKMARK";
export const DeleteBookmarkAction = S.struct({
  id: S.string.pipe(S.nonEmpty()),
  loanId: LoanId,
});
export type DeleteBookmarkAction = S.Schema.To<typeof DeleteBookmarkAction>;

export type UPDATE_POSITION_EVENT = "UPDATE_POSITION";
export const UpdatePositionAction = S.struct({
  position: Locator,
  loanId: LoanId,
});
export type UpdatePositionAction = S.Schema.To<typeof UpdatePositionAction>;

type ReaderInactive = { state: "reader-inactive"; message?: string };
type ReaderActivating = { state: "reader-activating"; loanId: LoanId };
type ReaderActive = { state: "reader-active"; loanId: LoanId };
type PlayerInactive = { state: "player-inactive"; message?: string };
type PlayerActivating = { state: "player-activating"; loanId: LoanId };
type PlayerActive = { state: "player-active"; loanId: LoanId };

export type ReaderState = ReaderInactive | ReaderActivating | ReaderActive;
export type PlayerState = PlayerInactive | PlayerActivating | PlayerActive;

export type STATE_CHANGED_EVENT = "STATE_CHANGED";
export const ReaderAbortedAction = S.struct({
  type: S.literal("reader-aborted"),
  message: S.optional(S.string),
});
export const PlayerAbortedAction = S.struct({
  type: S.literal("player-aborted"),
  message: S.optional(S.string),
});
export const ReaderActivatedAction = S.struct({
  type: S.literal("reader-activated"),
  loanId: LoanId,
});
export const PlayerActivatedAction = S.struct({
  type: S.literal("player-activated"),
  loanId: LoanId,
});
export const StateChangedAction = S.union(
  S.struct({
    type: S.union(
      S.literal("reader-deactivated"),
      S.literal("player-deactivated"),
    ),
  }),
  ReaderAbortedAction,
  PlayerAbortedAction,
  ReaderActivatedAction,
  PlayerActivatedAction,
);
export type StateChangedAction = S.Schema.To<typeof StateChangedAction>;

export type ReaderPluginEvent =
  | CREATE_BOOKMARK_EVENT
  | DELETE_BOOKMARK_EVENT
  | UPDATE_POSITION_EVENT
  | STATE_CHANGED_EVENT;

/**
 * Type definitions for publications. These are unique per fulfillment method.
 * e.g. All epubs fetched from Axis (protected with Axis DRM), share a type.
 */
export type AXIS_EPUB_TYPE = "AXIS_EPUB";
export type FINDAWAY_AUDIO_TYPE = "FINDAWAY_AUDIO";

/**
 * The union type for publications.
 */
export type PublicationType = AXIS_EPUB_TYPE | FINDAWAY_AUDIO_TYPE;

/**
 * Publication IDs must be unique non-empty strings.
 */
export const PublicationId = S.string.pipe(S.nonEmpty());
export type PublicationId = S.Schema.To<typeof PublicationId>;

/**
 * Data for Axis epubs.
 * Axis epubs are fetched and downloaded in web before opening in native.
 *
 * @property {string} publicationFileURL The file location of the downloaded epub.
 */
export const AxisEpubData = S.struct({
  publicationFileURL: S.string.pipe(S.nonEmpty()),
});
export type AxisEpubData = S.Schema.To<typeof AxisEpubData>;

/**
 * Data for Findaway audiobooks chapters.
 *
 * @property {number} duration Length of audio file in milliseconds.
 * @property {number} part_number Part number for books divided into parts, 0 if only one part.
 * @property {string | null} part_name Name of the part this chapter belongs to.
 * @property {number} chapter_number Chapter number within each part.
 * @property {string} chapter_name Name of this chapter.
 * @property {number} file_size Size of the file for this chapter in bytes.
 * @property {number} bitrate Bit rate of the file for this chapter in by.
 * @property {number} sample_rate Sample rate of the file for this chapter in hertz.
 * @property {string | null} format File format of the file for this chapter.
 */
const AudiobookChapter = S.struct({
  duration: S.number,
  part_number: S.number,
  part_name: S.union(S.string, S.null),
  chapter_number: S.number,
  chapter_name: S.string,
  file_size: S.number,
  bitrate: S.number,
  sample_rate: S.number,
  format: S.union(S.string, S.null),
});
export type AudiobookChapter = S.Schema.To<typeof AudiobookChapter>;

/**
 * Data for Findaway audiobooks.
 *
 * @property {string} contentId The content id given by Findaway fulfillment.
 * @property {string} licenseId The license id given by Findaway fulfillment.
 * @property {string} sessionKey The session key given by Findaway fulfillment.
 * @property {string[]} chapters The chapters of the audiobook
 * @property {string} title Title of the publication.
 * @property {string[]} authors Formatted list of authors of the publication.
 * @property {string[]} narrators Formatted list of narrators of the publication.
 * @property {string} coverImageUrl Url for the publication cover image.
 */
export const FindawayAudioData = S.struct({
  contentId: S.string.pipe(S.nonEmpty()),
  licenseId: S.string.pipe(S.nonEmpty()),
  sessionKey: S.string.pipe(S.nonEmpty()),
  chapters: S.optional(S.array(AudiobookChapter)),
  title: S.optional(S.string),
  authors: S.optional(S.array(S.string)),
  narrators: S.optional(S.array(S.string)),
  coverImageUrl: S.optional(S.string),
});

export type FindawayAudioData = S.Schema.To<typeof FindawayAudioData>;

/**
 * Publication data union type. This is used along side {@link PublicationType}
 * to fetch and open publications of different types and from many vendors.
 */
export const PublicationData = S.union(AxisEpubData, FindawayAudioData);
export type PublicationData = S.Schema.To<typeof PublicationData>;
