/**
 * There are two types of errors:
 *   1. Failures / Expected errors. These are errors that are part of our business logic
 *      and that we should be able to recover from within the app. Examples are a server
 *      response saying "you have too many books checked out", or a form input error.
 *   2. Defects / Unexpected errors. These errors mean something is broken with the application.
 *      Examples are a 500 error from the server, or localStorage not being available.
 *      These errors don't need a class of they're own. They can be caught by some generic error
 *      boundary or handler and simply logged to error reporting with whatever information we
 *      want to attach to it.
 *
 * So what does this mean?
 *  1. Only make a new error type if the app will handle the error in a specific way.
 *  2. Failures (expected errors) should provide enough information for the catching code to
 *     handle them and possibly show a message to the user.
 *  3. Defects (unexpected errors) should be generic Errors, and just need to provide enough information
 *     for the error reporting to be useful. The "patron facing message" will just be a generic message.
 */

export type SharedFailure = UnauthorizedError | FetchError | PageNotFoundError;

export abstract class Failure {
  _tag = "Failure";
  readonly _isFailure = true;
  readonly stack?: Array<string> | undefined;
  constructor(readonly cause?: unknown) {
    // get the stack trace from an error object.
    // and update it to remove the first few lines, which are
    // just the error constructors.
    const e = new Error();
    this.stack = e.stack?.split("\n");
    this.stack?.splice(0, 3);
  }
  // the message should be a patron-facing message describing what happened.
  abstract get message(): string;
}

/**
 * A fetch error is for when Fetch throws. We can choose to handle it by showing a "Can't connect"
 * message or similar. Otherwise it is a defect, so we only need to catch and turn it into a
 * FetchError if we plan to handle it somehow.
 */
export class FetchError extends Failure {
  readonly _tag = "FetchError";
  constructor(
    readonly url: string,
    readonly cause: unknown,
  ) {
    super(cause);
  }

  override get message(): string {
    return `FetchError: ${this.cause?.toString() ?? "Unknown Error"}`;
  }
}

export class UnauthorizedError extends Failure {
  readonly _tag = "UnauthorizedError";
  constructor(
    readonly patronMessage?: string,
    readonly title?: string,
  ) {
    super();
  }

  override get message(): string {
    return `UnauthorizedError: ${this.patronMessage}`;
  }
}

export class PageNotFoundError extends Failure {
  readonly _tag = "PageNotFoundError";
  constructor(
    readonly url: string,
    readonly patronMessage?: string,
  ) {
    super();
  }

  override get message(): string {
    return `PageNotFoundError: ${this.url}`;
  }
}

/**
 * A defect is an error we don't plan to handle. We don't always have to catch
 * errors thrown by our code or third parties and wrap them in Defect classes, but
 * in some cases it is useful. Defects allow us to provide a better dev message and
 * attach arbitrary additional context to the error (in info) that might help debugging.
 */
export class Defect extends Error {
  readonly _tag = "Defect";
  readonly cause?: unknown;
  readonly stack?: string | undefined;
  [k: string]: unknown;
  constructor(
    readonly devMessage: string,
    info?: {
      cause?: unknown;
      [k: string]: unknown;
    },
  ) {
    const { cause, ...rest } = info ?? {};
    super(devMessage, { cause, ...rest });
    this.cause = cause;
    // either build a stack trace or use the cause
    if (cause instanceof Error) {
      this.stack = cause.stack;
    } else {
      const e = new Error();
      const stack = e.stack?.split("\n");
      // the first 3 lines are this error constructor, so not helpful
      stack?.splice(0, 3);
      this.stack = stack?.join("\n");
    }
    // add any other info to the error object
    Object.assign(this, rest);
  }

  get message(): string {
    return `Defect: ${this.devMessage}`;
  }
}

export function isDefect(e: unknown): e is Defect {
  return e instanceof Defect;
}
