import { Defect } from "@ereading/shared/errors";
import { CirculationManager } from "oe-shared/CirculationManager";

/**
 * Simply exporting processed env vars
 */
export const NEW_RELIC_API_KEY = process.env.NEW_RELIC_API_KEY;
export const NEW_RELIC_LICENSE_KEY = process.env.NEW_RELIC_LICENSE_KEY;
export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY;
export const ADOBE_ANALYTICS_URL = process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_URL;
export const CM_URL = process.env.NEXT_PUBLIC_CM_URL;
if (!CM_URL) throw new Defect("process.env.CM_URL is undefined.");
export const CM = new CirculationManager(CM_URL, "USOEI", "Open eBooks");
export const REACT_AXE = process.env.REACT_AXE === "true";
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const NODE_ENV = process.env.NODE_ENV;
export const IS_SERVER = typeof window === "undefined";
export const APP_VERSION = process.env.APP_VERSION;
export const BUILD_ID = process.env.BUILD_ID;
export const GIT_COMMIT_SHA = process.env.GIT_COMMIT_SHA;
export const RELEASE_STAGE = process.env.RELEASE_STAGE;
