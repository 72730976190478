import { handleCMError, handleFetchError } from "../../fetch/errors";
import { isFirstBookAuthCredentials, json } from "../../fetch/helpers";
import {
  FirstbookBearerJWT,
  FirstbookJWTResponse,
  OpenEBooksAuthCredentials,
} from "../types";

/**
 * Checks First Book credentials to determine if a user's token
 * can be refreshed. If credentials are not First Book or
 * the token is not within 5 minutes of expiration, return the
 * existing credentials.
 */
export async function verifyToken(
  credentials: OpenEBooksAuthCredentials,
  oauthEndpoint: string,
): Promise<OpenEBooksAuthCredentials> {
  if (isFirstBookAuthCredentials(credentials)) {
    const tokenHasExpired = tokenExpired(credentials);
    if (tokenHasExpired) {
      console.log("Token has expired, refreshing...");
      const { token, expiresAt } = await fetchFirstbookJWT(
        credentials.basicToken,
        oauthEndpoint,
      );
      return { ...credentials, token, expiresAt };
    }
    return { ...credentials };
  }
  return { ...credentials };
}

export function tokenExpired(credentials: OpenEBooksAuthCredentials): boolean {
  if (isFirstBookAuthCredentials(credentials)) {
    const existingExpiration = credentials.expiresAt;
    const now = Date.now();
    const expiration = new Date(existingExpiration).valueOf();
    const FIVE_MIN = 5 * 60 * 1000; // convert 5 minutes to milliseconds
    return expiration - now <= FIVE_MIN;
  }
  return false;
}

export const fetchFirstbookJWT = async (
  basicToken: string,
  oauthEndpoint: string,
): Promise<FirstbookBearerJWT> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const firstbookJwtResponse: FirstbookJWTResponse = await fetch(
    oauthEndpoint,
    {
      method: "GET",
      headers: {
        Authorization: basicToken,
      },
    },
  )
    .catch(handleFetchError(oauthEndpoint))
    .then(handleCMError)
    .then(json);

  const { access_token, token_type, expires_in, root_lane, is_new, age_group } =
    firstbookJwtResponse;

  const expirationInMilliseconds = expires_in * 1000;
  const expiresAt = new Date(
    Date.now() + expirationInMilliseconds,
  ).toUTCString();

  return {
    token: `${token_type} ${access_token}`,
    expiresAt,
    rootLane: root_lane,
    isNew: is_new,
    ageGroup: age_group,
  };
};
