import Script from "next/script";

export const GTMScript = () => (
  <Script id="google-tag-manager">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-572L9MV');`}</Script>
);

// for non-JS users, if there are any...
export const GTMNoscript = () => (
  <noscript>
    <iframe
      title="GTM Noscript"
      src="https://www.googletagmanager.com/ns.html?id=GTM-572L9MV"
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    ></iframe>
  </noscript>
);
