import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import { GetStaticProps } from "next";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LayoutPage from "components/chakra-ui/LayoutPage";
import { Carousel } from "components/chakra-ui/Carousel";
import { CATALOG_ROOT_PATH } from "utils/constants";
import {
  CollectionBook,
  fetchLostCollection,
} from "components/chakra-ui/fetching";
import Link from "next/link";

type CollectionProps = {
  lostCollection?: CollectionBook;
};

const NotFoundPage = ({ lostCollection }: CollectionProps) => {
  return (
    <LayoutPage>
      <Flex alignItems="start" gap={3} m={[8, 8, 12]}>
        <Text as="h1" color="ui.teal.400" fontSize={["3rem", "3rem", "6rem"]}>
          404
        </Text>
        <Stack mt={[3, 3, 6]}>
          <Text as="p" fontSize={["md", "md", "xl"]} fontWeight="normal">
            We&apos;re sorry...
            <br />
            Page Not Found
          </Text>
          <Flex
            alignItems={["start", "center"]}
            direction={["column", "row"]}
            gap={2}
          >
            <Text as="p" fontSize={["md", "md", "xl"]} fontWeight="normal">
              Go to
            </Text>
            <Link href={CATALOG_ROOT_PATH} legacyBehavior passHref>
              <Button
                as="a"
                colorScheme="teal"
                leftIcon={<HomeOutlinedIcon />}
                size={["sm", "sm", "md"]}
              >
                Home
              </Button>
            </Link>
          </Flex>
        </Stack>
      </Flex>
      {lostCollection && lostCollection.books.length && (
        <Flex direction="column" mb="3rem">
          <Text
            as="h2"
            fontSize={["3xl", "3xl", "9xl"]}
            fontWeight="light"
            ml={[8, 8, 12]}
          >
            Are you Lost?
          </Text>
          <Carousel books={lostCollection.books} colorScheme="teal" />
        </Flex>
      )}
    </LayoutPage>
  );
};

export default NotFoundPage;

export const getStaticProps: GetStaticProps = async () => {
  try {
    const lostCollection = await fetchLostCollection();

    return {
      props: {
        lostCollection,
      },
    };
    // If there's an issue fetching the lostCollection,
    // just don't pass the prop to `NotFoundPage` and the
    // `Are you Lost?` lane won't render.
  } catch (_error) {
    return {
      props: {},
    };
  }
};
